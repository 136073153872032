import { Component } from '@angular/core';

@Component({
  selector: 'app-tab-solicitare',
  templateUrl: './tab-solicitare.component.html',
  styleUrl: './tab-solicitare.component.scss'
})
export class TabSolicitareComponent {
  public solicitariTrimise = [
    { id: 1, solicitare: 'Solicitare 1', data: '2024-02-01', status: 'Trimis' },
    { id: 2, solicitare: 'Solicitare 2', data: '2024-02-15', status: 'În așteptare' },
    { id: 3, solicitare: 'Solicitare 3', data: '2024-03-01', status: 'Finalizată' }
  ];

  public nouaSolicitare: string = '';

  adaugaSolicitare() {
    if (this.nouaSolicitare.trim()) {
      const newSolicitare = {
        id: this.solicitariTrimise.length + 1,
        solicitare: this.nouaSolicitare,
        data: new Date().toISOString().split('T')[0],
        status: 'În așteptare'
      };
      this.solicitariTrimise.push(newSolicitare);
      this.nouaSolicitare = '';  // Resetare câmp
    }
  }
}
