<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mx-auto"> <!-- Navbar centrat pe desktop -->
        <ng-container *ngFor="let item of menuItems">
          <li class="nav-item dropdown">
            <a class="nav-link" 
               [routerLink]="item.link" 
               aria-expanded="item.open" 
               (click)="item.open = !item.open">
              {{ item.denumire_meniu }}
              <span *ngIf="item.subsectiuni && item.subsectiuni.length > 0" class="caret"></span>
            </a>
            <ul class="dropdown-menu" *ngIf="item.subsectiuni && item.open">
              <ng-container *ngFor="let subsection of item.subsectiuni">
                <li class="dropdown-submenu">
                  <a class="dropdown-item " 
                     [routerLink]="subsection.link" 
                     (click)="toggleSubsection(subsection, item)">
                    {{ subsection.denumire }}
                    <span *ngIf="subsection.subsectiuni && subsection.subsectiuni.length > 0" class="dropdown-toggle"></span>
                  </a>
                  <ul class="dropdown-menu" *ngIf="subsection.subsectiuni && subsection.subsectiuni.length > 0 && subsection.open">
                    <ng-container *ngFor="let subSubsection of subsection.subsectiuni">
                      <li>
                        <a class="dropdown-item" [routerLink]="subSubsection.link">{{ subSubsection.denumire }}</a>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</nav>
