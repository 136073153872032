
// src/app/menu.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


export interface MenuItem {
  name: string;
  iconClasses: string;
  description: string;
  path: string[];
  children?: MenuItem[];
}


@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuUrl = 'assets/data/menu.json';
  private menuSPVUrl = 'assets/data/spv-menu.json'; 

  constructor(private http: HttpClient) {}

  getMenuItems(): Observable<any[]> {
    return this.http.get<any[]>(this.menuUrl);
  }

  getSPVMenu(): Observable<{ MENU: MenuItem[] }> {
    return this.http.get<{ MENU: MenuItem[] }>(this.menuSPVUrl);
  }
}
