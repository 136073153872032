import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';
import  menuData  from '../../../../assets/data/spv-menu.json';
import { Router } from '@angular/router';
import { User } from '@/models/user';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {

    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user : User = null;
    public coduriFiscale : String[];
    //public menu = MENU;
    public menu = (menuData as any).MENU;

    constructor(
        public appService: AppService,
        private store: Store<AppState>,
        private router: Router
    ) {}

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.getAuthenthificatedUser();
        this.coduriFiscale = this.appService.getCoduriFiscale();
    }

    onSocietateChange(value: any){
        console.log(value)
        localStorage.setItem('codFiscal',value);
        this.router.navigate(['/spv',value]);
    }

    hasAccess(tag : String): boolean{
        return this.appService.hasAccesss(tag)
    }

    hasRole(role : String): boolean{
        return this.appService.hasRole(role)
    }
}

// export const MENU = [
//     {
//         name: 'Alege contextul',
//         iconClasses: 'fas fa-folder',
//         children: [
//             {
//                 name: 'ABC SRL',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/1']
//             },
//             {
//                 name: 'XYZ SA',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/2']
//             }
//         ]
//     },
//     {
//         name: 'Dashboard',
//         iconClasses: 'fas fa-tachometer-alt',
//         path: ['/spv']
//     },
//     {
//         name: 'Mesaje',
//         iconClasses: 'fas fa-tachometer-alt',
//         path: ['/mesaje']
//     },
//     {
//         name: 'Solicitari',
//         iconClasses: 'fas fa-file',
//         path: ['/spv/solicitari']
//     },
//     {
//         name: 'Plăți creanțe',
//         iconClasses: 'far fa-address-book',
//         path: ['/spv/plati']
//     },
//     {
//         name: 'Programare online',
//         iconClasses: 'far fa-address-book',
//         path: ['/spv/programare']
//     },
//     {
//         name: 'Buletin fiscal',
//         iconClasses: 'far fa-address-book',
//         path: ['/spv/buletin-fiscal']
//     },
//     {
//         name: 'Declaratii',
//         iconClasses: 'far fa-address-book',
//         path: ['/spv/declaratii']
//     },
//     {
//         name: 'eFactura',
//         iconClasses: 'far fa-address-book',
//         path: ['/spv/e-factura']
//     },
//     {
//         name: 'eTransport',
//         iconClasses: 'far fa-address-book',
//         path: ['/spv/e-transport']
//     }
// ];

// export const MENU = [
//     {
//         name: 'Alege contextul',
//         iconClasses: 'fas fa-folder',
//         children: [
//             {
//                 name: 'ABC SRL',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/1']
//             },
//             {
//                 name: 'XYZ SA',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/2']
//             }
//         ]
//     },
//     {
//         name: 'Dashboard',
//         iconClasses: 'fas fa-tachometer-alt',
//         path: ['/spv']
//     },
//     {
//         name: 'Solicitari',
//         iconClasses: 'fas fa-file',
//         path: ['/spv/solicitari'],
//         children: [
//             {
//                 name: 'Eliberari documente',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/solicitari']
//             },
//             {
//                 name: 'Informatii',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/solicitari']
//             },
//             {
//                 name: 'Obligatii de plata',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/solicitari']
//             },
//             {
//                 name: 'Solicitari',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/solicitari']
//             },
//             {
//                 name: 'Raspunsuri solicitari',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/solicitari']
//             },
//             {
//                 name: 'Status solicitari',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/solicitari']
//             },
//             {
//                 name: 'Arhiva solicitari',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/solicitari']
//             }
//         ]
//     },
//     {
//         name: 'Dosar electronic',
//         iconClasses: 'fas fa-folder',
//         path: ['/spv/dosar-electronic'],
//         children: [
//             {
//                 name: 'Depunere declarații fiscale',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/dosar-electronic']
//             },
//             {
//                 name: 'Calendar fiscal',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/dosar-electronic']
//             },
//             {
//                 name: 'Recipise,',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/dosar-electronic']
//             },
//             {
//                 name: 'Arhivă declarații fiscale',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/dosar-electronic']
//             },
//         ]
//     },
//     {
//         name: 'Plăți creanțe',
//         iconClasses: 'fas fa-file',
//         path: ['/spv/plati'],    
//         children: [
//             {
//                 name: 'Plăți creanțe',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/plati']
//             },
//             {
//                 name: 'Extrase de cont',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/plati']
//             },
//             {
//                 name: 'Eșalonări și înlesniri la plată',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/plati']
//             },
//             {
//                 name: 'Răspunsuri eșalonări și înlesniri',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/plati']
//             },
//             {
//                 name: 'Status solicitare eșalonare',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/plati']
//             },
//             {
//                 name: 'Arhivă eșalonări',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/plati']
//             }
//         ]
//     },
//     {
//         name: 'Notificari',
//         iconClasses: 'fas fa-bell',
//         path: ['/spv/notificari'],
//         children: [
//             {
//                 name: 'Notificări emise',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/notificari']
//             },
//             {
//                 name: 'Răspuns notificări',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/notificari']
//             },
//             {
//                 name: 'Arhivă notificări',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/notificari']
//             }
//         ]
//     },
//     {
//         name: 'E-servicii',
//         iconClasses: 'fas fa-spinner',
//         path: ['/spv/e-servicii'],
//         children: [
//             {
//                 name: 'E-factura',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/e-servicii']
//             },
//             {
//                 name: 'E-transport',
//                 iconClasses: 'fas fa-file',
//                 path: ['/spv/e-servicii']
//             },
//             {
//                 name: 'E-TVA',
//                 iconClasses: 'far fa-address-book',
//                 path: ['/spv/e-servicii']
//             }
//         ]
//     },
//     {
//         name: 'Programare',
//         iconClasses: 'fas fa-calendar',
//         path: ['/spv/programari']
//     }
// ];
