import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@modules/main/pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';


import {CommonModule, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {SidebarSearchComponent} from './components/sidebar-search/sidebar-search.component';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import { environment } from 'environments/environment';
import { MainPageComponent } from './modules/main-page/main-page/main-page.component';
import { MainPageModule } from '@modules/main-page/main-page.module';
import { MainPageAdminModule } from '@modules/main-page-admin/main-page-admin.module';
import { InboxComponent } from './modules/main/pages/inbox/inbox.component';
import { LegislatieComponent } from '@modules/main-page/pages/legislatie/legislatie.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProgramareComponent } from './modules/main/pages/programare/programare.component';
import { SolicitariComponent } from './modules/main/pages/solicitari/solicitari.component';
import { DosarElectronicComponent } from './modules/main/pages/dosar-electronic/dosar-electronic.component';
import { PlatiComponent } from './modules/main/pages/plati/plati.component';
import { NotificariComponent } from './modules/main/pages/notificari/notificari.component';
import { EServiciiComponent } from './modules/main/pages/e-servicii/e-servicii.component';
import { TabDeclaratiiComponent } from './modules/main/pages/inbox/tab-declaratii/tab-declaratii.component';
import { TabSolicitariComponent } from './modules/main/pages/inbox/tab-solicitari/tab-solicitari.component';
import { TabActeAdministrativeComponent } from './modules/main/pages/inbox/tab-acte-administrative/tab-acte-administrative.component';
import { TabInformatiiComponent } from './modules/main/pages/inbox/tab-informatii/tab-informatii.component';
import { TabInformatiiGeneraleComponent } from './modules/main/pages/inbox/tab-informatii-generale/tab-informatii-generale.component';
import { PlatiCreanteComponent } from './modules/main/pages/plati-creante/plati-creante.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeclaratiiComponent } from './modules/main/pages/declaratii/declaratii.component';
import { TabRecipiseDeclaratieComponent as TabDepunereDeclaratieComponent } from './modules/main/pages/declaratii/tab-recipise-declaratie/tab-recipise-declaratie.component';
import { TabArhivaDeclaratiiComponent } from './modules/main/pages/declaratii/tab-arhiva-declaratii/tab-arhiva-declaratii.component';
import { DeclaratieFormularComponent } from './modules/main/pages/declaratii/declaratie-formular/declaratie-formular.component';
import { TabPlatiComponent } from './modules/main/pages/plati-creante/tab-plati/tab-plati.component';
import { TabArhivaPlatiComponent } from './modules/main/pages/plati-creante/tab-arhiva-plati/tab-arhiva-plati.component';
import { TabSolicitareComponent } from './modules/main/pages/solicitari/tab-solicitare/tab-solicitare.component';
import { TabArhivaSolicitariComponent } from './modules/main/pages/solicitari/tab-arhiva-solicitari/tab-arhiva-solicitari.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field'; // Importați MatFormFieldModule
import { MatInputModule } from '@angular/material/input'; // Importați MatInputModule 
import { MatButtonModule } from '@angular/material/button'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AdminUsersComponent } from './modules/main/admin/pages/admin-users/admin-users.component';
import { TabUsersComponent } from './modules/main/admin/pages/admin-users/tab-users/tab-users.component';
import { TabAssignRolesComponent } from './modules/main/admin/pages/admin-users/tab-assign-roles/tab-assign-roles.component';
import { TabAssignTagsComponent } from './modules/main/admin/pages/admin-users/tab-assign-tags/tab-assign-tags.component';
registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        InboxComponent,
        ProgramareComponent,
        SolicitariComponent,
        DosarElectronicComponent,
        PlatiComponent,
        NotificariComponent,
        EServiciiComponent,
        TabDeclaratiiComponent,
        TabSolicitariComponent,
        TabActeAdministrativeComponent,
        TabInformatiiComponent,
        TabInformatiiGeneraleComponent,
        PlatiCreanteComponent,
        DeclaratiiComponent,
        TabDepunereDeclaratieComponent,
        TabArhivaDeclaratiiComponent,
        DeclaratieFormularComponent,
        TabPlatiComponent,
        TabArhivaPlatiComponent,
        TabSolicitareComponent,
        TabArhivaSolicitariComponent,
        AdminUsersComponent,
        TabUsersComponent,
        TabAssignRolesComponent,
        TabAssignTagsComponent
    ],
    imports: [
        FormsModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        MatDialogModule,
        MatSortModule,
        MatFormFieldModule, // Adaugă acest modul
        MatInputModule,     // Adaugă acest modul
        MatButtonModule,  
        ProfabricComponentsModule,
        MainPageModule,
        MainPageAdminModule,
        CommonModule,
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        NgxGoogleAnalyticsModule.forRoot(environment.GA_ID),
        NgxPaginationModule,
        FormsModule,
        NgbModule
    ],
    providers: [
    provideAnimationsAsync()
  ],
    bootstrap: [AppComponent]
})
export class AppModule {}
