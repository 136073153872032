<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h3>Utilizatori</h3>
        </mat-card-title>
        <mat-card-subtitle>
            <div class="d-flex justify-content-end align-items-center w-100">
                <button class="btn btn-success btn-sm me-2" (click)="openModal(addUserModal)">
                    Adaugă Utilizator
                </button>
                <div class="input-group" style="width: 250px;"> <!-- Setează lățimea dorită pentru input -->
                    <input type="text" class="form-control" placeholder="Filtrează utilizatorii" 
                           (keyup)="applyFilter($event)" aria-label="Filtrare utilizatori">
                </div>
            </div>
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>

        <table mat-table [dataSource]="users" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="nume">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nume
                    Prenume</th>
                <td mat-cell *matCellDef="let user">{{ user.nume + ' ' +
                    user.prenume }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let user">{{ user.email }}</td>
            </ng-container>

            <ng-container matColumnDef="roles">
                <th mat-header-cell *matHeaderCellDef
                    mat-sort-header>Roluri</th>
                <td mat-cell *matCellDef="let user">
                    <div *ngFor="let role of user.roles">
                        <span class="badge badge-primary">{{ role }}</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="tags">
                <th mat-header-cell *matHeaderCellDef
                    mat-sort-header>Etichete</th>
                <td mat-cell *matCellDef="let user">
                    <div *ngFor="let tag of user.tags">
                        <span class="badge badge-primary">{{ tag }}</span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Acțiuni</th>
                <td mat-cell *matCellDef="let user">
                    <button class="btn btn-primary btn-sm mr-2"
                        (click)="editUser(user)">Editează</button>
                    <button class="btn btn-danger btn-sm"
                        (click)="deleteUser(user)">Șterge</button>
                </td>
            </ng-container>

            <!-- Rânduri de antet -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Rânduri de date -->
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <!-- Paginator pentru tabel -->
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons></mat-paginator>
    </mat-card-content>
</mat-card>

<ng-template #addUserModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title" id="userModalLabel">Adaugă Utilizator</h5>
        <button type="button" class="close" aria-label="Close" (click)="d()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label for="nume">Nume</label>
                <input type="text" class="form-control" id="nume"
                    [(ngModel)]="newUser.nume" name="nume"
                    placeholder="Introduceti numele" required>

                <label for="prenume">Prenume</label>
                <input type="text" class="form-control" id="prenume"
                    [(ngModel)]="newUser.prenume" name="prenume"
                    placeholder="Introduceti prenumele" required>

                <label for="email">Email</label>
                <input type="email" class="form-control" id="email"
                    [(ngModel)]="newUser.email" name="email"
                    placeholder="Introduceti email-ul" required>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
            (click)="d()">Renunta</button>

        <button *ngIf="!isEditing" type="button" class="btn btn-primary"
            (click)="addUser()">Adaugă</button>
        <button *ngIf="isEditing" type="button" class="btn btn-primary"
            (click)="addUser()">Editeaza</button>
    </div>
</ng-template>
