<div class="container mt-3">
  <div class="form-group">
    <label for="tipDeclaratie">Tip Declarație:</label>
    <select id="tipDeclaratie" class="form-control " [(ngModel)]="declaratiaAleasa">
      <!-- <option value="" disabled selected>Selectează o declarație</option> -->
      <option *ngFor="let tip of tipuriDeclaratii" [value]="tip.value" [ngClass]="{'disabled selected' : tip.value == 0}">
        {{ tip.label }}
      </option>
    </select>
  </div>

  <div *ngIf="showForm()">
    <h3>Formular pentru {{ declaratiaAleasa }}</h3>

    <ng-container *ngIf="declaratiaAleasa === '200'">
      <form>
        <div class="form-group">
          <label for="nume">Nume:</label>
          <input type="text" class="form-control" id="nume" required>
        </div>
        <div class="form-group">
          <label for="cnp">CNP:</label>
          <input type="text" class="form-control" id="cnp" required>
        </div>
        <div class="form-group">
          <label for="venit">Venit Total:</label>
          <input type="number" class="form-control" id="venit" required>
        </div>
        <button type="submit" class="btn btn-primary">Trimite</button>
      </form>
    </ng-container>

    <ng-container *ngIf="declaratiaAleasa === '300'">
      <form>
        <div class="form-group">
          <label for="codFiscal">Cod Fiscal:</label>
          <input type="text" class="form-control" id="codFiscal" required>
        </div>
        <div class="form-group">
          <label for="tva">TVA Total:</label>
          <input type="number" class="form-control" id="tva" required>
        </div>
        <button type="submit" class="btn btn-primary">Trimite</button>
      </form>
    </ng-container>

    <ng-container *ngIf="declaratiaAleasa === '101'">
      <form>
        <div class="form-group">
          <label for="profit">Profit:</label>
          <input type="number" class="form-control" id="profit" required>
        </div>
        <button type="submit" class="btn btn-primary">Trimite</button>
      </form>
    </ng-container>

    <ng-container *ngIf="declaratiaAleasa === '100'">
      <div class="container mt-3">     
        <form>
          <div class="form-group">
            <label for="numeSocietate">Numele Societății:</label>
            <input type="text" class="form-control" id="numeSocietate" placeholder="Introduceți numele societății" required>
          </div>
      
          <div class="form-group">
            <label for="codFiscal100">Cod Fiscal:</label>
            <input type="text" class="form-control" id="codFiscal100" placeholder="Introduceți codul fiscal" required>
          </div>
      
          <div class="form-group">
            <label for="sumaImpozit">Suma de Plată:</label>
            <input type="number" class="form-control" id="sumaImpozit" placeholder="Introduceți suma de impozit" required>
          </div>
      
          <div class="form-group">
            <label for="dataDepunere">Data Depunerii:</label>
            <input type="date" class="form-control" id="dataDepunere" required>
          </div>
      
          <div class="form-group">
            <label for="anFiscal">Anul Fiscal:</label>
            <input type="number" class="form-control" id="anFiscal" placeholder="Introduceți anul fiscal" required>
          </div>
      
          <div class="form-group">
            <label for="modelDeDeclarație">Modelul de Declarație:</label>
            <select id="modelDeDeclarație" class="form-control" required>
              <option value="" disabled selected>Selectați modelul</option>
              <option value="standard">Model Standard</option>
              <option value="simplificat">Model Simplificat</option>
            </select>
          </div>
      
          <div class="form-group">
            <label for="observatii">Observații:</label>
            <textarea class="form-control" id="observatii" rows="3" placeholder="Introduceți observațiile, dacă este cazul"></textarea>
          </div>
      
          <button type="submit" class="btn btn-primary mt-3">Trimite Declarația</button>
        </form>
      </div>
      
    </ng-container>
  </div>
</div>
