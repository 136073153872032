import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@modules/main/pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import { AdminGuard } from '@guards/admin.guards';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { MainPageComponent } from '@modules/main-page/main-page/main-page.component';
import { InboxComponent } from '@modules/main/pages/inbox/inbox.component';
import { AnafComponent } from '@modules/main-page/pages/anaf/anaf.component';
import { LegislatieComponent } from '@modules/main-page/pages/legislatie/legislatie.component';
import { TransparentaComponent } from '@modules/main-page/pages/transparenta/transparenta.component';
import { ContactComponent } from '@modules/main-page/pages/contact/contact.component';
import { AcasaComponent } from '@modules/main-page/pages/acasa/acasa.component';
import { ServiciiComponent } from '@modules/main-page/pages/servicii/servicii.component';
import { ProgramareComponent } from '@modules/main/pages/programare/programare.component';
import { SolicitariComponent } from '@modules/main/pages/solicitari/solicitari.component';
import { DosarElectronicComponent } from '@modules/main/pages/dosar-electronic/dosar-electronic.component';
import { PlatiComponent } from '@modules/main/pages/plati/plati.component';
import { NotificariComponent } from '@modules/main/pages/notificari/notificari.component';
import { EServiciiComponent } from '@modules/main/pages/e-servicii/e-servicii.component';
import { PlatiCreanteComponent } from '@modules/main/pages/plati-creante/plati-creante.component';
import { DeclaratiiComponent } from '@modules/main/pages/declaratii/declaratii.component';
import { AdminUsersComponent } from '@modules/main/admin/pages/admin-users/admin-users.component';

const routes: Routes = [
    {
        path: 'spv',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent,
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                children: [
                    {
                        path: 'admin',
                        component: AdminUsersComponent
                    },
                ]
            },
            {
                path: 'admin',
                component: AdminUsersComponent,
                canActivate: [AdminGuard]
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: 'inbox',
                component: InboxComponent
            },
            {
                path: 'solicitari',
                component: SolicitariComponent
            },
            {
                path: 'dosar-electronic',
                component: DosarElectronicComponent
            },
            {
                path: 'plati',
                component: PlatiCreanteComponent
            },
            {
                path: 'notificari',
                component: NotificariComponent
            },
            {
                path: 'e-servicii',
                component: EServiciiComponent
            },
            {
                path: 'programare',
                component: ProgramareComponent
            },
            {
                path: 'mesaje',
                component: InboxComponent
            },
            {
                path: 'solicitari',
                component: SolicitariComponent
            },
            {
                path: 'declaratii',
                component: DeclaratiiComponent
            },            
            {
                path: '',
                component: DashboardComponent
            },
            {
                path: ':id',
                component: DashboardComponent
            },
        ]
    },
    {
        path: '',
        component: MainPageComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: AcasaComponent,
                canActivate: [NonAuthGuard]
            },
            {
                path: 'anaf',
                component: AnafComponent,
                canActivate: [NonAuthGuard]
            },
            {
                path: 'legislatie',
                component: LegislatieComponent,
                canActivate: [NonAuthGuard]
            },
            {
                path: 'transparenta',
                component: TransparentaComponent,
                canActivate: [NonAuthGuard]
            },
            {
                path: 'servicii',
                component: ServiciiComponent,
                canActivate: [NonAuthGuard]
            },
            {
                path: 'contact',
                component: ContactComponent,
                canActivate: [NonAuthGuard]
            }

        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    // {
    //     path: 'register',
    //     component: RegisterComponent,
    //     canActivate: [NonAuthGuard]
    // },
    // {
    //     path: 'forgot-password',
    //     component: ForgotPasswordComponent,
    //     canActivate: [NonAuthGuard]
    // },
    // {
    //     path: 'recover-password',
    //     component: RecoverPasswordComponent,
    //     canActivate: [NonAuthGuard]
    // },    
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
