import { Component } from '@angular/core';

@Component({
  selector: 'app-tab-acte-administrative',
  templateUrl: './tab-acte-administrative.component.html',
  styleUrl: './tab-acte-administrative.component.scss'
})
export class TabActeAdministrativeComponent {

}
