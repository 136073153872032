<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h3>Atribuire Etichete Utilizatorilor</h3>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <table mat-table [dataSource]="users" class="mat-elevation-z8">
            <ng-container matColumnDef="nume">
                <th mat-header-cell *matHeaderCellDef>Nume Prenume</th>
                <td mat-cell *matCellDef="let user">{{ user.nume + ' ' + user.prenume }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let user">{{ user.email }}</td>
            </ng-container>

            <ng-container matColumnDef="tags">
                <th mat-header-cell *matHeaderCellDef>Etichete</th>
                <td mat-cell *matCellDef="let user">
                    <div *ngFor="let tag of user.tags">
                        <span class="badge badge-primary">{{ tag }}</span>
                        <button (click)="removeTag(tag, user)" class="btn btn-danger btn-sm ms-1">X</button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Acțiuni</th>
                <td mat-cell *matCellDef="let user">
                    <button class="btn btn-primary btn-sm" (click)="openModal(addTagModal, user)">Gestionează Etichete</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-card-content>
</mat-card>

<!-- Modal pentru gestionarea etichetelor -->
<ng-template #addTagModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title" id="tagModalLabel">Gestionare Etichete pentru {{ user.nume }} {{ user.prenume }}</h5>
        <button type="button" class="close" (click)="d()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4>Etichete existente:</h4>
        <div class="mb-3">
            <h4>Etichete existente:</h4>
            <div class="row"> <!-- Folosește clasa row pentru a organiza etichetele -->
                <ng-container *ngFor="let tag of user.tags">
                    <div class="col-4 mb-2 d-flex align-items-center"> <!-- Setează clasa col-4 -->
                        <span class="badge badge-primary me-2">{{ tag }}</span>
                        <button (click)="removeTag(tag, user)" class="btn btn-danger btn-sm">X</button>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="mb-3">
            <label for="newTag" class="form-label">Adaugă eticheta:</label>
            <div class="input-group">
                <select id="newTag" [(ngModel)]="newTag" class="form-control">
                    <option *ngFor="let availableTag of availableTags" [value]="availableTag">{{ availableTag }}</option>
                </select>
                <button (click)="addTag()" class="btn btn-primary">Adaugă Etichetă</button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="d()">Renunță</button>
    </div>
</ng-template>

