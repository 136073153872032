// src/app/admin.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppService } from '../services/app.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private appservice: AppService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isAdmin = this.appservice.isSpvAdmin();

    // Dacă utilizatorul nu este admin, redirecționează-l la o altă pagină
    if (!isAdmin) {
      this.router.navigate(['/']); // Poți schimba ruta unde dorești să redirecționezi
      return false;
    }
    return true; // Permite accesul la ruta
  }
}
