<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/spv']">SPV</a></li>
          <li class="breadcrumb-item active">Mesaje</li>
        </ol>
      </div>
    </div>
  </div>
</div>
<div class="content">
  <div class="container-fluid">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Mesaje</h3>
        </div>
        <div class="card-body">
          <div class="container mt-3">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab"
                  href="#declaratii">Declarații</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab"
                  href="#solicitari">Solicitări</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab"
                  href="#acteAdministrare">Acte Administrarea Fiscala</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab"
                  href="#informatii">Informații</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab"
                  href="#informatiiGenerale">Informații Generale</a>
              </li>
            </ul>

            <div class="tab-content">
              <div id="declaratii" class="tab-pane fade show active">
                <app-tab-declaratii></app-tab-declaratii>
              </div>
              <div id="solicitari" class="tab-pane fade">
                <app-tab-solicitari></app-tab-solicitari>
              </div>
              <div id="acteAdministrare" class="tab-pane fade">
                <app-tab-acte-administrative></app-tab-acte-administrative>
              </div>
              <div id="informatii" class="tab-pane fade">
                <app-tab-informatii></app-tab-informatii>
              </div>
              <div id="informatiiGenerale" class="tab-pane fade">
                <app-tab-informatii-generale></app-tab-informatii-generale>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
