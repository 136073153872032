<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-12">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a
                            [routerLink]="['/spv']">SPV</a></li>
                    <li class="breadcrumb-item active">Plati creante</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="container-fluid">
        <div class="container">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Plati creante</h3>
                </div>
                <div class="card-body">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab"
                                href="#plati">Plati creante</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab"
                                href="#arhiva">Arhiva plati</a>
                        </li>                       
                    </ul>
                    <div class="tab-content">
                        <div id="plati" class="tab-pane fade show active">
                            <app-tab-plati></app-tab-plati>
                        </div>
                        <div id="arhiva" class="tab-pane fade">
                            <app-tab-arhiva-plati></app-tab-arhiva-plati>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
