<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h3>Atribuire Roluri Utilizatorilor</h3>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <table mat-table [dataSource]="users" class="mat-elevation-z8">
            <ng-container matColumnDef="nume">
                <th mat-header-cell *matHeaderCellDef>Nume Prenume</th>
                <td mat-cell *matCellDef="let user">{{ user.nume + ' ' + user.prenume }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let user">{{ user.email }}</td>
            </ng-container>

            <ng-container matColumnDef="roles">
                <th mat-header-cell *matHeaderCellDef>Roluri</th>
                <td mat-cell *matCellDef="let user">
                    <div *ngFor="let role of user.roles">
                        <span class="badge badge-primary">{{ role }}</span>
                        <button (click)="removeRole(role, user)" class="btn btn-danger btn-sm ms-1">X</button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Acțiuni</th>
                <td mat-cell *matCellDef="let user">
                    <button class="btn btn-primary btn-sm mr-2" (click)="openModal(roleModal, user)">Gestionează Roluri</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-card-content>
</mat-card>

<ng-template #roleModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title" id="userModalLabel">Gestionare Roluri pentru {{ user.nume }} {{ user.prenume }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <h4>Roluri existente:</h4>
            <div class="mb-3">
                <div *ngFor="let role of user.roles" class="d-flex align-items-center mb-2">
                    <span class="badge badge-primary me-2">{{ role }}</span>
                    <button (click)="removeRole(role, user)" class="btn btn-danger btn-sm">X</button>
                </div>
            </div>
            <div class="mb-3">
                <label for="newRole" class="form-label">Adaugă rol:</label>
                <div class="input-group">
                    <select id="newRole" [(ngModel)]="newRole" class="form-control">
                        <option *ngFor="let role of availableRoles" [value]="role">{{ role }}</option>
                    </select>
                    <button (click)="addRole()" class="btn btn-primary">Adaugă Rol</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="d()">Renunta</button>

        <!-- <button *ngIf="!isEditing" type="button" class="btn btn-primary" (click)="addUser()">Adaugă</button>
        <button *ngIf="isEditing" type="button" class="btn btn-primary" (click)="addUser()">Editeaza</button> -->
    </div>
</ng-template>
