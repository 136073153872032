import { Component, OnInit } from '@angular/core';
import { MenuService } from '@services/menu.service';
import menuData from '../../../../assets/data/menu.json';

export interface Subsection {
  denumire: string; // Schimbat din Denumire în denumire
  link: string;     // Schimbat din Link în link
  subsectiuni?: Subsection[]; // Schimbat Subsecțiuni în subsectiuni
}

export interface MenuItem {
  denumire_meniu: string; // Schimbat din DenumireMeniu în denumire_meniu
  link: string;          // Schimbat din Link în link
  subsectiuni?: Subsection[]; // Schimbat Subsecțiuni în subsectiuni
}


@Component({
  selector: 'main-page-dynamic-menu',
  templateUrl: './dynamic-menu.component.html',
  styleUrls: ['./dynamic-menu.component.scss']
})
export class DynamicMenuComponent implements OnInit {
  menuItems: MenuItem[] = [];
  menu: any;
  constructor ( private menuSerice: MenuService) {

  }

  ngOnInit() {
    this.menuItems = menuData["locatie_nivel_meniu"].map(item => ({
      denumire_meniu: item["denumire_meniu"],
      link: item["link"],
      subsectiuni: item["subsectiuni"] ? item["subsectiuni"].map(sub => ({
        denumire: sub["denumire"],
        link: sub["link"],
        subsectiuni: sub["subsectiuni"] ? sub["subsectiuni"].map(subSub => ({
          denumire: subSub["denumire"],
          link: subSub["link"],
          open: false // Adăugăm un câmp pentru a controla deschiderea
        })) : [],
        open: false // Adăugăm un câmp pentru a controla deschiderea subsecțiunii
      })) : [],
      open: false // Adăugăm un câmp pentru a controla deschiderea meniului principal
    }));

  }
  
  toggleSubsection(selectedSubsection: any, parentItem: any) {
    if (selectedSubsection.open) {
      // Dacă subsecțiunea este deja deschisă, închide-o
      selectedSubsection.open = false;
    } else {
      // Închide toate celelalte subsecțiuni
      parentItem.subsectiuni.forEach(sub => {
        sub.open = false; // Închide subsecțiunile
      });
      // Deschide subsecțiunea selectată
      selectedSubsection.open = true;
    }
  }
  
  

    // JSON-ul tău
    menuJson = {
      "locatie_nivel_meniu": [
          {
              "denumire_meniu": "Despre ANAF",
              "link": "/despre-anaf",
              "subsectiuni": [
                  {
                      "denumire": "Conducere ANAF",
                      "link": "/conducere-anaf",
                      "subsectiuni": [
                          {
                              "denumire": "Demnitari ANAF",
                              "link": "/demnitari-anaf"
                          },
                          {
                              "denumire": "Agenda conducerii ANAF",
                              "link": "/agenda-conducerii-anaf"
                          }
                      ]
                  },
                  {
                    "denumire": "Info ANAF",
                    "link": "/info-anaf",
                    "subsectiuni": [
                        {
                            "denumire": "Consultare publică",
                            "link": "/consultare-publica"
                        },
                        {
                            "denumire": "Protecția datelor cu caracter personal",
                            "link": "/protectia-datelor"
                        },
                        {
                            "denumire": "Registrul Unic al Transparenței Intereselor",
                            "link": "/registrul-unic-transparentei"
                        },
                        {
                            "denumire": "Relații internaționale",
                            "link": "/relatii-internationale",
                            "subsectiuni": [
                                {
                                    "denumire": "Prezentare",
                                    "link": "/prezentare-relatii-internationale"
                                },
                                {
                                    "denumire": "Proiecte",
                                    "link": "/proiecte-relatii-internationale",
                                    "subsectiuni": [
                                        {
                                            "denumire": "Fonduri europene preaderare - finalizate",
                                            "link": "/fonduri-europene-preaderare-finalizate"
                                        },
                                        {
                                            "denumire": "Fonduri structurale",
                                            "link": "/fonduri-structurale"
                                        },
                                        {
                                            "denumire": "Fonduri ENPI",
                                            "link": "/fonduri-enpi"
                                        },
                                        {
                                            "denumire": "Fonduri FSE",
                                            "link": "/fonduri-fse",
                                            "subsectiuni": [
                                                {
                                                    "denumire": "Comunicate Fonduri FSE",
                                                    "link": "/comunicate-fonduri-fse"
                                                },
                                                {
                                                    "denumire": "Consolidarea capacității Agenției Naționale de Administrare Fiscală de a susține inițiativele de modernizare",
                                                    "link": "/consolidare-capacitate-anaf"
                                                },
                                                {
                                                    "denumire": "Îmbunătățirea sistemului de monitorizare a tranzacțiilor intracomunitare - cod SIPOCA 702",
                                                    "link": "/imbunatatire-monitorizare-tranzactii"
                                                }
                                            ]
                                        },
                                        {
                                            "denumire": "Program Hercule III 2014-2020",
                                            "link": "/program-hercule-iii-2014-2020"
                                        },
                                        {
                                            "denumire": "Fonduri ENI",
                                            "link": "/fonduri-eni"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "denumire": "Banca Mondială",
                            "link": "/banca-mondiala",
                            "subsectiuni": [
                                {
                                    "denumire": "RAMP",
                                    "link": "/ramp"
                                }
                            ]
                        },
                        {
                            "denumire": "Granturi Norvegiene 2009 - 2014",
                            "link": "/granturi-norvegiene-2009-2014"
                        },
                        {
                            "denumire": "Granturi Norvegiene",
                            "link": "/granturi-norvegiene",
                            "subsectiuni": [
                                {
                                    "denumire": "Fonduri bilaterale 2009-2014",
                                    "link": "/fonduri-bilaterale-2009-2014"
                                }
                            ]
                        },
                        {
                            "denumire": "Granturi Elvețiene",
                            "link": "/granturi-elvetiene"
                        },
                        {
                            "denumire": "Anunțuri",
                            "link": "/anunturi",
                            "subsectiuni": [
                                {
                                    "denumire": "Valorificare bunuri din proprietatea privată a statului",
                                    "link": "/valorificare-bunuri-proprietate-privata"
                                },
                                {
                                    "denumire": "Valorificare prin licitație a bunurilor sechestrate",
                                    "link": "/valorificare-licita-bunuri-sechestrate"
                                },
                                {
                                    "denumire": "Valorificare prin vânzare directă a bunurilor sechestrate",
                                    "link": "/valorificare-vanzare-bunuri-sechestrate"
                                },
                                {
                                    "denumire": "Anunțuri de achiziție de bunuri și servicii",
                                    "link": "/anunturi-achizitie-bunuri-servicii"
                                },
                                {
                                    "denumire": "Anunțuri acte administrative fiscale",
                                    "link": "/anunturi-acte-administrative-fiscale"
                                },
                                {
                                    "denumire": "Executări silite cu darea în plată",
                                    "link": "/executari-siliate"
                                },
                                {
                                    "denumire": "Lista centralizată a bunurilor perisabile",
                                    "link": "/lista-bunurilor-perisabile"
                                },
                                {
                                    "denumire": "Anunțuri bunuri perisabile",
                                    "link": "/anunturi-bunuri-perisabile"
                                },
                                {
                                    "denumire": "Valorificări bunuri perisabile",
                                    "link": "/valorificari-bunuri-perisabile"
                                },
                                {
                                    "denumire": "Anunțuri de interes general",
                                    "link": "/anunturi-interes-general"
                                }
                            ]
                        },
                        {
                            "denumire": "Buletin informativ în baza Legii nr. 544/2001",
                            "link": "/buletin-informativ-lege-544"
                        },
                        {
                            "denumire": "Harta site",
                            "link": "/harta-site"
                        }
                    ]
                },
                  {
                    "denumire": "Organizare",
                    "link": "/organizare",
                    "subsectiuni": [
                        {
                            "denumire": "Regulamentul de organizare și funcționare",
                            "link": "/regulament-organizare"
                        },
                        {
                            "denumire": "Organigramă",
                            "link": "/organigrama"
                        },
                        {
                            "denumire": "Unități subordonate",
                            "link": "/unitati-subordonate"
                        },
                        {
                            "denumire": "Resurse umane",
                            "link": "/resurse-umane"
                        }
                    ]
                },
                {
                  "denumire": "Strategii ANAF",
                  "link": "/strategii-anaf",
                  "subsectiuni": [
                      {
                          "denumire": "Programe și strategii",
                          "link": "/programe-strategii"
                      },
                      {
                          "denumire": "Proiecte de digitalizare",
                          "link": "/proiecte-digitalizare",
                          "subsectiuni": [
                              {
                                  "denumire": "SAF-T",
                                  "link": "/saf-t"
                              },
                              {
                                  "denumire": "E-Factura",
                                  "link": "/e-factura"
                              },
                              {
                                  "denumire": "OSS",
                                  "link": "/oss"
                              },
                              {
                                  "denumire": "SIPOCA 739",
                                  "link": "/sipo-ca-739"
                              }
                          ]
                      },
                      {
                          "denumire": "Rapoarte și studii",
                          "link": "/rapoarte-studii"
                      }
                  ]
              }
              ]
          },
          {
              "denumire_meniu": "Asistență Contribuabili",
              "link": "/asistenta-contribuabili",
              "subsectiuni": [
                  {
                      "denumire": "Informații privind obligațiile fiscale",
                      "link": "/informatii-obligatii-fiscale",
                      "subsectiuni": [
                          {
                              "denumire": "Calendarul obligațiilor fiscale",
                              "link": "/calendar-obligatii-fiscale"
                          }
                      ]
                  },
                  {
                      "denumire": "Declararea obligațiilor fiscale",
                      "link": "/declararea-obligatiilor-fiscale",
                      "subsectiuni": [
                          {
                              "denumire": "Toate formularele, cu explicații",
                              "link": "/formulare-explicatii"
                          },
                          {
                              "denumire": "Norme de venit",
                              "link": "/norme-venit"
                          },
                          {
                              "denumire": "Prețuri medii produse agricole",
                              "link": "/preturi-medii-agricole"
                          },
                          {
                              "denumire": "Codurile CAEN",
                              "link": "/coduri-caen"
                          },
                          {
                              "denumire": "Cursuri valutare",
                              "link": "/cursuri-valutare"
                          }
                      ]
                  },
                  {
                      "denumire": "Plata obligațiilor fiscale",
                      "link": "/plata-obligatii-fiscale",
                      "subsectiuni": [
                          {
                              "denumire": "Codurile IBAN",
                              "link": "/coduri-iban"
                          },
                          {
                              "denumire": "Nomenclatoarele obligațiilor",
                              "link": "/nomenclatoare-obligatii"
                          }
                      ]
                  },
                  {
                      "denumire": "Programe utile",
                      "link": "/programe-utile"
                  },
                  {
                      "denumire": "Legislație",
                      "link": "/legislatie",
                      "subsectiuni": [
                          {
                              "denumire": "Buletin informativ fiscal",
                              "link": "/buletin-informativ-fiscal"
                          },
                          {
                              "denumire": "Codul fiscal",
                              "link": "/codul-fiscal"
                          },
                          {
                              "denumire": "Codul de procedură fiscală",
                              "link": "/cod-procedura-fiscala"
                          },
                          {
                              "denumire": "Circulare ANAF",
                              "link": "/circulare-anaf"
                          },
                          {
                              "denumire": "Alte acte normative",
                              "link": "/alte-acte-normative"
                          }
                      ]
                  },
                  {
                      "denumire": "Acorduri internaționale",
                      "link": "/acorduri-internationale",
                      "subsectiuni": [
                          {
                              "denumire": "Conventii pentru evitarea dublei impuneri",
                              "link": "/conventii-evitare-dubla-impunere"
                          },
                          {
                              "denumire": "Acorduri internaționale",
                              "link": "/acorduri-internationale-2"
                          }
                      ]
                  },
                  {
                      "denumire": "Servicii oferite contribuabililor",
                      "link": "/servicii-oferite-contribuabililor",
                      "subsectiuni": [
                          {
                              "denumire": "Servicii electronice oferite contribuabililor",
                              "link": "/servicii-electronice"
                          },
                          {
                              "denumire": "Ghiduri curente și alte materiale informative",
                              "link": "/ghiduri-materiale"
                          },
                          {
                              "denumire": "Întrebări și răspunsuri pe Facebook",
                              "link": "/intrebari-raspunsuri-facebook"
                          },
                          {
                              "denumire": "Baza de date cu întrebări și răspunsuri – ANAFI",
                              "link": "/baza-intrebari-anaf"
                          },
                          {
                              "denumire": "Adrese sedii ANAF dotate cu calculatoare destinate Self – Service",
                              "link": "/adrese-sedii-anaf"
                          },
                          {
                              "denumire": "Întâlniri cu contribuabilii",
                              "link": "/intalniri-contribuabili"
                          },
                          {
                              "denumire": "Acces date prin SMS",
                              "link": "/acces-date-sms"
                          },
                          {
                              "denumire": "Catalogul serviciilor oferite contribuabililor",
                              "link": "/catalog-servicii"
                          }
                      ]
                  },
                  {
                      "denumire": "Carta contribuabilului",
                      "link": "/carta-contribuabilului"
                  }
              ]
          },
          {
              "denumire_meniu": "Servicii Online",
              "link": "/servicii-online",
              "subsectiuni": [
                  {
                      "denumire": "Declarații electronice",
                      "link": "/declaratii-electronice"
                  },
                  {
                      "denumire": "Înregistrare/Înrolare persoane fizice și juridice în Spațiul Privat Virtual",
                      "link": "/inregistrare-spatiul-privat-virtual"
                  },
                  {
                      "denumire": "Programări online",
                      "link": "/programari-online"
                  },
                  {
                      "denumire": "Verificare documente electronice eliberate în Spațiul Privat Virtual",
                      "link": "/verificare-documente-eliberate"
                  },
                  {
                      "denumire": "Registre",
                      "link": "/registre",
                      "subsectiuni": [
                          {
                              "denumire": "Registrul RO e-Factura",
                              "link": "/registrul-e-factura"
                          },
                          {
                              "denumire": "Registrul persoanelor impozabile înregistrate în scopuri de TVA",
                              "link": "/registrul-tva"
                          },
                          {
                              "denumire": "Registrul persoanelor impozabile care aplică TVA la încasare",
                              "link": "/registrul-tva-incasare"
                          },
                          {
                              "denumire": "Registrul persoanelor care aplică plata defalcată a TVA",
                              "link": "/registrul-plata-defalcata-tva"
                          },
                          {
                              "denumire": "Registrul contribuabililor inactivi/reactivați",
                              "link": "/registrul-inactivi-reactivati"
                          },
                          {
                              "denumire": "Registrul agricultorilor care aplică regimul special",
                              "link": "/registrul-agricultorilor"
                          },
                          {
                              "denumire": "Registrul entităților/unităților de cult",
                              "link": "/registrul-entitati-unitati-cult"
                          },
                          {
                              "denumire": "Lista persoanelor impozabile care au depus D089 pe propria răspundere – Energie electrică",
                              "link": "/lista-d089-energie-electrica"
                          },
                          {
                              "denumire": "Lista persoanelor impozabile care au depus D089 pe propria răspundere – Gaze naturale",
                              "link": "/lista-d089-gaze-naturale"
                          }
                      ]
                  },
                  {
                      "denumire": "Rapoarte tranzacții și transferuri",
                      "link": "/rapoarte-tranzactii-transferuri"
                  },
                  {
                      "denumire": "Servicii WEB – ANAF",
                      "link": "/servicii-web-anaf"
                  },
                  {
                      "denumire": "Lista persoanelor impozabile care au depus D089 pe propria răspundere",
                      "link": "/lista-d089"
                  },
                  {
                      "denumire": "Obligații restante către bugete",
                      "link": "/obligatii-restante-bugete"
                  },
                  {
                      "denumire": "Aparate de marcat electronice fiscale (case de marcat)",
                      "link": "/aparate-marcat-electronic"
                  },
                  {
                      "denumire": "Rambursare TVA din UE",
                      "link": "/rambursare-tva-ue"
                  },
                  {
                      "denumire": "Stadiul de soluționare al unui decont cu suma negativă de TVA",
                      "link": "/stadiu-solutionare-decont"
                  },
                  {
                      "denumire": "Notificări",
                      "link": "/notificari"
                  },
                  {
                      "denumire": "Aplicații în curs de dezvoltare pentru interesul contribuabililor",
                      "link": "/aplicatii-dezvoltare"
                  },
                  {
                      "denumire": "PATRIMVEN - specificații tehnice",
                      "link": "/patrimven-specificatii"
                  },
                  {
                      "denumire": "One Stop Shop",
                      "link": "/one-stop-shop"
                  }
              ]
          },
          {
              "denumire_meniu": "Info publice",
              "link": "/info-publice",
              "subsectiuni": [
                  {
                      "denumire": "Informații de interes public",
                      "link": "/informatii-interes-public",
                      "subsectiuni": [
                          {
                              "denumire": "Numele și prenumele persoanei responsabile pentru Legea 544/2001",
                              "link": "/responsabil-lege-544"
                          },
                          {
                              "denumire": "Modalități de contestare",
                              "link": "/modalitati-contestare"
                          },
                          {
                              "denumire": "Lista documente de interes public și lista cu documente produse/gestionate de instituție",
                              "link": "/lista-documente-interes-public"
                          },
                          {
                              "denumire": "Rapoarte de aplicare a Legii 544/2001",
                              "link": "/rapoarte-aplicare-lege-544"
                          },
                          {
                              "denumire": "Buletin informativ în baza Legii nr. 544/2001",
                              "link": "/buletin-informativ-lege-544"
                          },
                          {
                              "denumire": "Buget",
                              "link": "/buget",
                              "subsectiuni": [
                                  {
                                      "denumire": "Buget pe surse financiare",
                                      "link": "/buget-surse-financiare"
                                  },
                                  {
                                      "denumire": "Situația plăților",
                                      "link": "/situatia-platilor"
                                  },
                                  {
                                      "denumire": "Situația drepturilor salariale",
                                      "link": "/situatia-drepturilor-salariale"
                                  }
                              ]
                          },
                          {
                              "denumire": "Bilanțuri contabile",
                              "link": "/bilanturi-contabile"
                          },
                          {
                              "denumire": "Declarații de avere și interese",
                              "link": "/declaratii-avere-interese",
                              "subsectiuni": [
                                  {
                                      "denumire": "Declarații de avere și interese",
                                      "link": "/declaratii-avere-interese-2"
                                  },
                                  {
                                      "denumire": "Ghidul de completare a declarațiilor de avere și interese",
                                      "link": "/ghid-completare-declaratii"
                                  }
                              ]
                          },
                          {
                              "denumire": "Informații privind agenții economici",
                              "link": "/informatii-agenti-economici",
                              "subsectiuni": [
                                  {
                                      "denumire": "Listele contribuabililor mari și mijlocii",
                                      "link": "/liste-contribuabili-mari-mijlocii"
                                  },
                                  {
                                      "denumire": "Antrepozite fiscale autorizate",
                                      "link": "/antrepozite-fiscale-autorizate"
                                  }
                              ]
                          },
                          {
                              "denumire": "Formulare tip",
                              "link": "/formulare-tip"
                          },
                          {
                              "denumire": "Situația bunurilor imobile intrate în proprietatea statului",
                              "link": "/situatia-bunurilor-imobile"
                          },
                          {
                              "denumire": "Lista achizițiilor publice de produse, servicii și lucrări",
                              "link": "/lista-achizitii-publice"
                          },
                          {
                              "denumire": "Ordin nr. 830/10-X-2006 al președintelui ANAF",
                              "link": "/ordin-830-10-x-2006"
                          },
                          {
                              "denumire": "RSS",
                              "link": "/rss"
                          }
                      ]
                  },
                  {
                      "denumire": "Plan de măsuri pentru eficientizarea colectării veniturilor la Bugetul General Consolidat",
                      "link": "/plan-masuri-echientizare"
                  },
                  {
                      "denumire": "Servicii oferite de ANAF",
                      "link": "/servicii-oferite-anaf"
                  },
                  {
                      "denumire": "Formular de contact",
                      "link": "/formular-contact"
                  },
                  {
                      "denumire": "Strategia Națională Anticorupție 2016 - 2020",
                      "link": "/strategia-anticoruptie"
                  },
                  {
                      "denumire": "Trasabilitate produse din TUTUN",
                      "link": "/trasabilitate-produse-tutun"
                  },
                  {
                      "denumire": "Situația deconturilor de TVA cu opțiune de rambursare",
                      "link": "/situatia-deconturilor-tva-rambursare"
                  }
              ]
          },
          {
              "denumire_meniu": "Info UE",
              "link": "/info-ue",
              "subsectiuni": [
                  {
                      "denumire": "Sancțiuni internaționale",
                      "link": "/sanctiuni-internationale",
                      "subsectiuni": [
                          {
                              "denumire": "Cadrul juridic privind sancțiunile internaționale",
                              "link": "/cadru-juridic-sancțiuni"
                          },
                          {
                              "denumire": "Regimuri sancționatorii",
                              "link": "/regimuri-sanctionatorii"
                          },
                          {
                              "denumire": "Ordine de blocare/deblocare",
                              "link": "/ordine-blocare-deblocare"
                          },
                          {
                              "denumire": "Informații utile privind sancțiunile internaționale",
                              "link": "/informatii-utile-sancțiuni"
                          }
                      ]
                  },
                  {
                      "denumire": "Info Brexit",
                      "link": "/info-brexit"
                  },
                  {
                      "denumire": "Rambursări TVA din UE",
                      "link": "/rambursari-tva-ue"
                  },
                  {
                      "denumire": "VIES solicitare verificare cod TVA",
                      "link": "/vies-verificare-cod-tva"
                  },
                  {
                      "denumire": "VIES verificarea codului TVA",
                      "link": "/vies-verificare-cod-tva-2"
                  },
                  {
                      "denumire": "EMCS",
                      "link": "/emcs",
                      "subsectiuni": [
                          {
                              "denumire": "Prima pagină",
                              "link": "/prima-pagina-emcs"
                          },
                          {
                              "denumire": "Descriere EMCS",
                              "link": "/descriere-emcs",
                              "subsectiuni": [
                                  {
                                      "denumire": "Ce este EMCS?",
                                      "link": "/ce-este-emcs"
                                  },
                                  {
                                      "denumire": "Baza legală a EMCS",
                                      "link": "/baza-legala-emcs"
                                  },
                                  {
                                      "denumire": "Schema simplă de funcționare",
                                      "link": "/schema-simple-emcs"
                                  },
                                  {
                                      "denumire": "Etapele de dezvoltare",
                                      "link": "/etapele-dezvoltare-emcs"
                                  },
                                  {
                                      "denumire": "Caiete de sarcini",
                                      "link": "/caiete-sarcini-emcs"
                                  }
                              ]
                          },
                          {
                              "denumire": "Funcționalitate",
                              "link": "/functionalitate-emcs",
                              "subsectiuni": [
                                  {
                                      "denumire": "Ce reprezintă FESS?",
                                      "link": "/ce-reprezinta-fess"
                                  },
                                  {
                                      "denumire": "Descrierea funcționării sistemului",
                                      "link": "/descriere-functionare-sistem"
                                  },
                                  {
                                      "denumire": "SEED",
                                      "link": "/seed"
                                  },
                                  {
                                      "denumire": "Prototip EMCS",
                                      "link": "/prototip-emcs"
                                  },
                                  {
                                      "denumire": "Glosar",
                                      "link": "/glosar-emcs"
                                  },
                                  {
                                      "denumire": "Noutăți",
                                      "link": "/noutati-emcs"
                                  },
                                  {
                                      "denumire": "Contact EMCS",
                                      "link": "/contact-emcs"
                                  }
                              ]
                          }
                      ]
                  },
                  {
                      "denumire": "ICS2 - Sistemul de control al importurilor",
                      "link": "/ics2-control-importuri"
                  }
              ]
          },
          {
              "denumire_meniu": "Contact",
              "link": "/contact",
              "subsectiuni": [
                  {
                      "denumire": "Formular de contact",
                      "link": "/formular-contact"
                  },
                  {
                      "denumire": "Call Center Asistență Contribuabili",
                      "link": "/call-center-asistenta-contribuabili"
                  },
                  {
                      "denumire": "Relații publice/mass media",
                      "link": "/relatii-publice"
                  },
                  {
                      "denumire": "Contact unități fiscale",
                      "link": "/contact-unitati-fiscale"
                  },
                  {
                      "denumire": "Contact trezorerii",
                      "link": "/contact-trezorerii"
                  },
                  {
                      "denumire": "Afișare sediu unitate fiscală",
                      "link": "/afisare-sediu-unitate-fiscala"
                  },
                  {
                      "denumire": "Magazine proprii valorificare bunuri",
                      "link": "/magazine-valorificare-bunuri"
                  },
                  {
                      "denumire": "Programul de funcționare al instituției",
                      "link": "/program-functiune-institutie"
                  }
              ]
          }
      ]
  }
  
  
}
