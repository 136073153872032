import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tab-plati',
  templateUrl: './tab-plati.component.html',
  styleUrl: './tab-plati.component.scss'
})
export class TabPlatiComponent {

  constructor(private modalService: NgbModal, private toastr: ToastrService) { }

  public creante = [
    { id: 1, tip: 'Taxă pe venit', suma: 100, dataLimit: '2024-04-01' },
    { id: 2, tip: 'Taxă pe proprietate', suma: 250, dataLimit: '2024-04-10' },
    { id: 3, tip: 'Taxă auto', suma: 150, dataLimit: '2024-05-01' }
  ];

  public selectedCreanta: any;
  openModal(content: any, creanta: any) {
    this.selectedCreanta = creanta; // Setează creanța selectată
    this.modalService.open(content);
  }

  confirmPayment() {
    this.toastr.success(`Plata pentru ${this.selectedCreanta.tip} a fost efectuată cu succes!`)
    this.modalService.dismissAll(); // Închide modalul după confirmare
  }
}
