<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/spv']">SPV</a></li>
          <li class="breadcrumb-item active">Solicitari</li>
        </ol>
      </div>
    </div>
  </div>
</div>
<div class="content">
  <div class="container-fluid">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Solicitari</h3>
        </div>
        <div class="card-body">
          <div class="container mt-3">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#eliberari">Eliberări documente</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#informatii">Informații</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#obligatii">Obligații de plată</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#raspunsuri">Răspunsuri solicitări</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#statusSolicitare">Status solicitare</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#solicitari">Solicitări către alte instituții</a>
              </li>
            </ul>
          
            <div class="tab-content">
              <div id="eliberari" class="tab-pane fade show active">
                <app-tab-solicitare></app-tab-solicitare>
              </div>
              
              <div id="informatii" class="tab-pane fade">
                <app-tab-arhiva-solicitari></app-tab-arhiva-solicitari>
              </div>
          
              <div id="obligatii" class="tab-pane fade">
                <h3>Obligații de plată</h3>
                <p>{{ tabContent.obligatii }}</p>
              </div>
          
              <div id="raspunsuri" class="tab-pane fade">
                <h3>Răspunsuri solicitări</h3>
                <p>{{ tabContent.raspunsuri }}</p>
              </div>
          
              <div id="statusSolicitare" class="tab-pane fade">
                <h3>Status solicitare</h3>
                <p>{{ tabContent.statusSolicitare }}</p>
              </div>
          
              <div id="solicitari" class="tab-pane fade">
                <h3>Solicitări către alte instituții</h3>
                <p>{{ tabContent.solicitari }}</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
