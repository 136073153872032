<table class="table">
    <thead>
        <tr>
            <th>#</th>
            <th>Tip Declarație</th>
            <th>Data</th>
            <th>Status</th>
            <th>Acțiune</th> <!-- Adaugă un header pentru acțiune -->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of arhiva">
            <td>{{ item.id }}</td>
            <td>{{ item.tip }}</td>
            <td>{{ item.data }}</td>
            <td>{{ item.status }}</td>
            <td>
              <button class="btn btn-success" (click)="descarcaDeclaratie(item.id)">Descarcă Declarație</button> <!-- Butonul pentru descărcare -->
            </td>
        </tr>
    </tbody>
</table>
