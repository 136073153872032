import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tab-arhiva-declaratii',
  templateUrl: './tab-arhiva-declaratii.component.html',
  styleUrl: './tab-arhiva-declaratii.component.scss'
})

export class TabArhivaDeclaratiiComponent {

  public arhiva = [
    { id: 1, tip: 'Arhivă 1', suma: 200, data: '2023-12-01', status: 'Finalizată' },
    { id: 2, tip: 'Arhivă 2', suma: 300, data: '2023-11-15', status: 'Finalizată' },
    { id: 3, tip: 'Arhivă 3', suma: 450, data: '2023-10-10', status: 'Finalizată' }
  ];

  constructor(private toastr: ToastrService){}

  descarcaDeclaratie(id: number) {
    // Logica pentru descărcarea declarației (în funcție de aplicație).
    // Poți folosi un URL predefinit sau o cerere HTTP pentru a obține fișierul.

    // Exemplu de alertă (înlocuiește această parte cu logica de descărcare reală):
    this.toastr.info(`Declarația cu ID ${id} a fost descărcată!`)
 
    
    // Într-o aplicație reală, ai putea să folosești un serviciu care să facă cererea 
    // pentru a obține fișierul pentru descărcare.
}

}
