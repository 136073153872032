<table class="table">
    <thead>
        <tr>
            <th>#</th>
            <th>Tip Creanță</th>
            <th>Suma</th>
            <th>Data Limită</th>
            <th>Acțiune</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let creanta of creante">
            <td>{{ creanta.id }}</td>
            <td>{{ creanta.tip }}</td>
            <td>{{ creanta.suma }}</td>
            <td>{{ creanta.dataLimit }}</td>
            <td>
                <button class="btn btn-primary"
                    (click)="openModal(content, creanta)">Plătește</button>
            </td>
        </tr>
    </tbody>
</table>
    <!-- Modal -->
    <ng-template #content let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Plată</h4>
            <button type="button" class="btn-close" aria-label="Close"
                (click)="d()"></button>
        </div>
        <div class="modal-body">
            <p>Confirmi plata pentru {{ selectedCreanta?.tip }} cu suma
                de {{ selectedCreanta?.suma |
                currency:'RON':'symbol':'right' }}?</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary"
                (click)="d()">Anulează</button>
            <button type="button" class="btn btn-primary"
                (click)="confirmPayment()">Confirmă Plata</button>
        </div>
    </ng-template>