<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0">{{ translationService.translate("spatiu-de-lucru") }} - {{codFiscal}}</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/spv']">SPV</a></li>
          <li class="breadcrumb-item active">{{ translationService.translate("spatiu-de-lucru") }} SPV</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid">
    <div class="row">
      <ng-container *ngFor="let item of menu">
        <div class="col-md-4 mb-4">
        
          <!-- Card principal -->
          <a [routerLink]="item.path" *ngIf="hasAccess(item.tag)">
            <div class="card h-100">
              <div class="card-header">
                <span style="color: #024476;">{{ item.name }}</span>
              </div>
              <div class="card-body d-flex">
                <img [src]="item.image" alt="{{ item.name }}" class="mr-3" style="width: 100px;">
                <p class="card-text">{{ item.description }}</p>
              </div>
            </div>
          </a>
        </div>

        <!-- Carduri pentru submeniuri, dacă există -->
        <!-- <ng-container *ngIf="item.children">
          <ng-container *ngFor="let child of item.children">
            <div class="col-md-4 mb-4">
              <a [routerLink]="child.path">
                <div class="card border-secondary h-100">
                  <div class="card-header">
                    <span style="color: #024476;">{{ child.name }}</span>
                  </div>
                  <div class="card-body d-flex">
                    <img [src]="child.image" alt="{{ child.name }}" class="mr-3" style="width: 100px;">
                    <p class="card-text">{{ child.description }}</p>
                  </div>
                </div>
              </a>
            </div>
          </ng-container>
        </ng-container> -->
      </ng-container>
    </div>
  </div>
</section>

