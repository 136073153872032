<div class="container mt-5">
    <h2>Servicii On-line</h2>

    <input type="text" [(ngModel)]="searchText" placeholder="Caută acte..."
    class="form-control mb-3" />
    
    <table class="table table-bordered table-striped">
        <thead class="table-header-custom">
            <tr>
                <th>Informație</th>
                <th>Data Publicării/Actualizării</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Registrul RO e-Factura</td>
                <td>15.11.2021</td>
            </tr>
            <tr>
                <td>Informaţii publice din situaţiile financiare/raportările
                    contabile anuale aferente agenţilor economici</td>
                <td>20.01.2021</td>
            </tr>
            <tr>
                <td>Registrul persoanelor impozabile înregistrate în scopuri de
                    TVA, registrul persoanelor impozabile care aplică sistemul
                    TVA la încasare, registrul persoanelor care aplică plata
                    defalcată a TVA şi registrul contribuabililor
                    inactivi/reactivați</td>
                <td>21.03.2023</td>
            </tr>
            <tr>
                <td>Registrul agricultorilor care aplică regimul special</td>
                <td></td> <!-- Fără dată disponibilă -->
            </tr>
            <tr>
                <td>Registrul entităților/unităților de cult</td>
                <td>01.04.2019</td>
            </tr>
            <tr>
                <td>Prezentare servicii WEB SPV</td>
                <td>21.02.2018</td>
            </tr>
            <tr>
                <td>Servicii web pentru Sistemul naţional privind factura
                    electronică RO e-Factura</td>
                <td>08.06.2022</td>
            </tr>
            <tr>
                <td>Servicii web pentru Sistemul electronic integrat RO
                    e-Transport</td>
                <td>08.06.2022</td>
            </tr>
            <tr>
                <td>Servicii web protejate de OAUTH</td>
                <td>08.06.2022</td>
            </tr>
        </tbody>
    </table>
</div>