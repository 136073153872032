<table class="table">
    <thead>
        <tr>
            <th>#</th>
            <th>Tip Declarație</th>
            <th>Data</th>
            <th>Status</th>
            <th>Acțiune</th> <!-- Adaugă un header pentru acțiune -->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of recipise">
            <td>{{ item.id }}</td>
            <td>{{ item.tip }}</td>
            <td>{{ item.data }}</td>
            <td>{{ item.status }}</td>
            <td>
                <button 
                [ngClass]="{'btn btn-danger' : item.status === 'Eroare', 'btn btn-success' : item.status !== 'Eroare'}"  
                (click)="descarcaRecipisa(item.id)">
                Descarcă Recipisa
                </button> 
            </td>
        </tr>
    </tbody>
</table>


