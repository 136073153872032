import { Component } from '@angular/core';

@Component({
  selector: 'app-tab-arhiva-solicitari',
  templateUrl: './tab-arhiva-solicitari.component.html',
  styleUrl: './tab-arhiva-solicitari.component.scss'
})
export class TabArhivaSolicitariComponent {
  public solicitariTrimise = [
    { id: 1, solicitare: 'Solicitare 1', data: '2024-02-01', status: 'Trimis' },
    { id: 2, solicitare: 'Solicitare 2', data: '2024-02-15', status: 'În așteptare' },
    { id: 3, solicitare: 'Solicitare 3', data: '2024-03-01', status: 'Finalizată' }
  ];

  public nouaSolicitare: string = '';
}
