import { Component } from '@angular/core';

@Component({
  selector: 'app-tab-informatii-generale',
  templateUrl: './tab-informatii-generale.component.html',
  styleUrl: './tab-informatii-generale.component.scss'
})
export class TabInformatiiGeneraleComponent {

}
