<table class="table">
    <thead>
      <tr>
        <th>#</th>
        <th>Tip Act</th>
        <th>Data Emitere</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>Act 1</td>
        <td>2024-03-01</td>
        <td>Valabil</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Act 2</td>
        <td>2024-03-05</td>
        <td>Expirat</td>
      </tr>
    </tbody>
  </table>