import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppService } from '@services/app.service';
import { TranslationService } from '@services/translation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'main-page-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit{

  isUserConnected: boolean = false;
  
  @ViewChild('navbarToggler', { static: false }) navbarToggler: ElementRef;

  constructor(
    private authService: AppService,
    public translationService: TranslationService,
    private router: Router) { }

    
  ngOnInit(): void {
    // Verifică dacă utilizatorul este conectat
    this.isUserConnected = this.authService.isConnected();

    // // Aici poți să faci alte verificări sau logici necesare
    // if (this.isUserConnected) {
    //   console.log('Utilizatorul este conectat!');
    //   // Poți efectua operațiuni specifice pentru utilizatorul conectat
    // } else {
    //   console.log('Utilizatorul nu este conectat!');
    //   // Poți redirecționa utilizatorul către pagina de login sau poți afișa un mesaj
    // }
  }

  logout() {
    this.authService.logout();
  }


  navigateTo(route: string) {
    this.toggleNavbar(); // Ascunde meniul
    this.router.navigate([route]); // Navighează la ruta specificată
  }
  

  toggleNavbar() {
    const navbarCollapsible = document.getElementById('navbarNav');
    if (navbarCollapsible) {
      navbarCollapsible.classList.toggle('show'); // Toggle pentru clasa show
    }
  }
}
