import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tab-recipise-declaratie',
  templateUrl: './tab-recipise-declaratie.component.html',
  styleUrl: './tab-recipise-declaratie.component.scss'
})
export class TabRecipiseDeclaratieComponent {

  public recipise = [
    { id: 1, tip: 'Depunere 1',  data: '2024-02-01', status: 'Finalizată' },
    { id: 2, tip: 'Depunere 2',  data: '2024-02-15', status: 'Eroare' },
    { id: 3, tip: 'Depunere 3',  data: '2024-03-01', status: 'Finalizată' }
  ];

  constructor(private toastr: ToastrService){}

  descarcaRecipisa(id: number) {
    // Logica pentru descărcarea declarației (în funcție de aplicație).
    // Poți folosi un URL predefinit sau o cerere HTTP pentru a obține fișierul.

    // Exemplu de alertă (înlocuiește această parte cu logica de descărcare reală):
    this.toastr.info(`Recipisa cu ID ${id} a fost descărcată!`)
 
    
    // Într-o aplicație reală, ai putea să folosești un serviciu care să facă cererea 
    // pentru a obține fișierul pentru descărcare.
}
}
