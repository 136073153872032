<nav class="navbar navbar-expand-lg navbar-light bg-primary">
    <div class="container-fluid">
        <div class="d-flex flex-column align-items-start">
            <img src="../assets/img/apolodor_logo_full.png" [routerLink]="'/'" alt="ANAF" style="height: 50px; margin-right: 10px;">
            <p class="d-none d-md-block" style="margin: 0; color: white;">ANAF - Agenția Națională de Administrare Fiscală</p>
        </div>        
        <div class=" navbar-collapse" id="navbarNav2">
            <div class="d-flex align-items-center ml-auto">
                <span (click)="changeLanguage('ro')" class="language-selector mr-3" id="lang-ro" style="cursor: pointer;" [style.color]="isLanguageActive('ro') ? 'white' : '#ccc'"><b>RO</b></span>
                <span (click)="changeLanguage('en')" class="language-selector mr-3" id="lang-en" style="cursor: pointer;" [style.color]="isLanguageActive('en') ? 'white' : '#ccc'"><b>EN</b></span>
                <!-- <span (click)="changeLanguage('hu')" class="language-selector mr-3" id="lang-hu" style="cursor: pointer;" [style.color]="isLanguageActive('hu') ? 'white' : '#ccc'"><b>HU</b></span> -->

                <div class="d-flex align-items-center ml-auto search-container">
                    <div class="input-group">
                        <input type="text" class="form-control search-input" placeholder="Căutare..." aria-label="Căutare">
                        <div class="input-group-append">
                            <button class="btn btn-outline-light search-btn" type="button">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <a *ngIf="!isUserConnected" [routerLink]="'/login'" class="btn btn-primary ml-3" role="button">
                    <b>{{ translationService.translate('menu.autentificare') }}</b>
                </a>

                <a *ngIf="isUserConnected" (click)="logout()" class="btn btn-primary ml-3" role="button">
                    <b>{{ translationService.translate('menu.deconectare') }}</b>
                </a>
            </div>
        </div>
    </div>
</nav>
