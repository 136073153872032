import { AfterViewInit, Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-declaratie-formular',
  templateUrl: './declaratie-formular.component.html',
  styleUrls: ['./declaratie-formular.component.scss']
})
export class DeclaratieFormularComponent implements OnInit {

  public declaratiaAleasa: string | null = null;


  public tipuriDeclaratii = [
    { value: '0', label: 'Selectează o declarație' },
    { value: '200', label: 'Declarația 200 - Impozit pe venit' },
    { value: '300', label: 'Declarația 300 - TVA' },
    { value: '101', label: 'Declarația 101 - Impozit pe profit' },
    { value: '100', label: 'Declarația 100 - Impozit pe profit' }
  ];

  ngOnInit(): void {
    this.declaratiaAleasa = this.tipuriDeclaratii[0].value;
  }

  showForm() {
    return this.declaratiaAleasa !== null && this.declaratiaAleasa !== this.tipuriDeclaratii[0].value;
}


}