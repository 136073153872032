<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link">
    <pf-image
        src="../../../../assets/img/apolodor_logo_min.png"
        alt="AdminLTE Logo"
        class="brand-image"
        height="40"
        width="40"
        rounded="true"
        style="opacity: 0.8"></pf-image>
    <span class="brand-text font-weight-light">SPV</span>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div
        class="user-panel mt-3 pb-2 d-flex"
        style="justify-content: start; align-items: center">
        <pf-image
            [src]="user.image"
            fallbackSrc="assets/img/default-profile.png"
            style="margin-left: 12px"
            alt="User Image"
            width="34"
            height="34"
            rounded="true" />
        <div class="info">
            <a [routerLink]="['/profile']" class="d-block">
                {{ user.email }}
            </a>
        </div>
    </div>
    <div
        class="user-panel mt-3 pb-2 d-flex"
        style="justify-content: start; align-items: center">
        <div class="row">
            <div class="info" style="margin: auto;">
                <label for style="color: lightgray;">Alege societatea</label>
            </div>
            <div class="info" style="margin: auto;">
                <select
                    class="form-select"
                    aria-label="Default select example"
                    (change)="onSocietateChange($event.target.value)"
                    style="width: 200px; height: 35px;">
                    <option selected>Toate</option>
                    <option *ngFor="let societate of coduriFiscale"
                        [value]="societate">{{ societate }}</option>
                </select>
            </div>
        </div>

    </div>

    <div class="form-inline">
        <app-sidebar-search></app-sidebar-search>
    </div>
    <div *ngIf="user.tags.length > 0"
        class="user-panel mt-3 pb-2 d-flex"
        style="justify-content: start; align-items: center">
        <!-- Sidebar Menu -->
        <nav class="mt-2" style="overflow-y: hidden">
            <ul
                class="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false">
                <app-menu-item *ngFor="let item of menu" [menuItem]="item" />
            </ul>
        </nav>

    </div>
    <div *ngIf="hasRole('admin')"
        class="user-panel mt-3 pb-2 d-flex"
        style="justify-content: start; align-items: center">
        <!-- Sidebar Menu -->
        <nav class="mt-2" style="overflow-y: hidden">
            <ul
                class="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false">
                    <a
                        [routerLink]="'admin'"
                        [routerLinkActive]="'active'"
                        class="nav-link">
                        <i class="nav-icon "></i>
                        <p>Administrare utilizatori</p>
                    </a>
            </ul>
        </nav>
    </div>
</div>
