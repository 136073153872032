<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" style="text-align: center;" id="navbarNav">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" (click)="toggleNavbar()" id="navbarDropdown" role="button" aria-expanded="false">
            {{ translationService.translate('menu.acasa') }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" (click)="navigateTo('/anaf')" style="cursor: pointer;">Despre ANAF</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="navigateTo('/transparenta')" style="cursor: pointer;">{{ translationService.translate('menu.transparenta') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="navigateTo('/legislatie')" style="cursor: pointer;">{{ translationService.translate('menu.legislatie') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="navigateTo('/servicii')" style="cursor: pointer;">{{ translationService.translate('menu.servicii-online') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="navigateTo('/contact')" style="cursor: pointer;">{{ translationService.translate('menu.contact') }}</a>
        </li>

        <!-- Butonul SPV -->
        <li class="nav-item">
          <a class="btn btn-primary" (click)="navigateTo('/spv')" style="margin-left: 10px;">
            <b>SPV</b>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
