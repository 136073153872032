<table class="table">
    <thead>
        <tr>
            <th>#</th>
            <th>Tip Informație</th>
            <th>Detalii</th>
            <th>Data</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>1</td>
            <td>Informație 1</td>
            <td>Detaliu despre informație 1</td>
            <td>2024-03-10</td>
        </tr>
        <tr>
            <td>2</td>
            <td>Informație 2</td>
            <td>Detaliu despre informație 2</td>
            <td>2024-03-11</td>
        </tr>
    </tbody>
</table>