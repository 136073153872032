<table class="table">
    <thead>
        <tr>
            <th>#</th>
            <th>Tip Creanță</th>
            <th>Suma</th>
            <th>Data platii</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let creanta of creante">
            <td>{{ creanta.id }}</td>
            <td>{{ creanta.tip }}</td>
            <td>{{ creanta.suma }}</td>
            <td>{{ creanta.dataPlatii }}</td>
        </tr>
    </tbody>
</table>