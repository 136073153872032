// src/app/user.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private users: User[] = [];
  private usersUrl = '../assets/data/users.json'; 

  constructor(private http: HttpClient) {}

  // Obține toți utilizatorii din fișierul JSON
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.usersUrl).pipe(
      map(users => this.users = users), // Salvează utilizatorii în lista de utilizatori
      catchError(this.handleError<User[]>('getUsers', [])) // Gestionează erorile
    );
  }

  // Obține un utilizator după email
  getUser(email: string): Observable<User | undefined> {
    const user = this.users.find(u => u.email === email);
    return of(user); // Returnează utilizatorul din lista locală
  }

  // Creează un utilizator nou
  createUser(user: User): Observable<void> {
    console.log(user);
    this.users.push(user);
    return of(undefined);
  }

  // Actualizează un utilizator
  updateUser(updatedUser: User): Observable<void> {
    const userIndex = this.users.findIndex(u => u.email === updatedUser.email);
    if (userIndex !== -1) {
      this.users[userIndex] = updatedUser;
    }
    return of(undefined);
  }

  // Șterge un utilizator
  deleteUser(email: string): Observable<void> {
    this.users = this.users.filter(u => u.email !== email);
    return of(undefined);
  }

  // Adaugă un rol la utilizator
  addRole(email: string, role: string): Observable<void> {
    const user = this.users.find(u => u.email === email);
    if (user && !user.roles.includes(role)) {
      user.roles.push(role);
    }
    return of(undefined);
  }

  // Elimină un rol de la utilizator
  removeRole(email: string, role: string): Observable<void> {
    const user = this.users.find(u => u.email === email);
    if (user) {
      user.roles = user.roles.filter(r => r !== role);
    }
    return of(undefined);
  }

  // Adaugă un tag la utilizator
  addTag(email: string, tag: string): Observable<void> {
    const user = this.users.find(u => u.email === email);
    if (user && !user.tags.includes(tag)) {
      user.tags.push(tag);
    }
    return of(undefined);
  }

  // Elimină un tag de la utilizator
  removeTag(email: string, tag: string): Observable<void> {
    const user = this.users.find(u => u.email === email);
    if (user) {
      user.tags = user.tags.filter(t => t !== tag);
    }
    return of(undefined);
  }

  // Adaugă un cod fiscal la utilizator
  addCodFiscal(email: string, codFiscal: string): Observable<void> {
    const user = this.users.find(u => u.email === email);
    if (user && !user.coduri_fiscale.includes(codFiscal)) {
      user.coduri_fiscale.push(codFiscal);
    }
    return of(undefined);
  }

  // Elimină un cod fiscal de la utilizator
  removeCodFiscal(email: string, codFiscal: string): Observable<void> {
    const user = this.users.find(u => u.email === email);
    if (user) {
      user.coduri_fiscale = user.coduri_fiscale.filter(cf => cf !== codFiscal);
    }
    return of(undefined);
  }

  // Gestionează erorile
  private handleError<T>(operation = 'operation', result: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result); // permite aplicației să continue
    };
  }
}
