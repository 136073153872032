import { Component } from '@angular/core';

@Component({
  selector: 'app-declaratii',
  templateUrl: './declaratii.component.html',
  styleUrls: ['./declaratii.component.scss']
})
export class DeclaratiiComponent {




}
