import { Component } from '@angular/core';

@Component({
  selector: 'app-tab-informatii',
  templateUrl: './tab-informatii.component.html',
  styleUrl: './tab-informatii.component.scss'
})
export class TabInformatiiComponent {

}
