import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from '@/models/user'; // Asigură-te că calea este corectă
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public user: User | null = null;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private userService: UserService) { }

  async loginByAuth({ email, password }): Promise<void> {
    try {
      // Obține utilizatorii din JSON
      // const users = await this.getUsers().toPromise();
      const users = await this.userService.getUsers().toPromise();
      const user = users.find(u => u.email === email && u.password === password);

      if (user) {
        this.user = user;
        console.log(this.user)
        localStorage.setItem('authentication', JSON.stringify(this.user));
        this.router.navigate(['/']); // Navigare către pagina principală
        this.toastr.success('Login success');
      } else {
        this.toastr.error('Credentials are wrong!');
      }
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  // Metodă pentru a obține utilizatorii din users.json
  getUsers(): Observable<User[]> {
    return this.userService.getUsers();
  }

  // Obține utilizatorul autentificat
  getAuthenthificatedUser(): User | null {
    return this.user;
  }

  // Obține rolurile utilizatorului
  getRoles(): string[] {
    return this.user ? this.user.roles : [];
  }

  // Obține tag-urile utilizatorului
  getTags(): string[] {
    return this.user ? this.user.tags : [];
  }

  // Obține codurile fiscale ale utilizatorului
  getCoduriFiscale(): string[] {
    return this.user ? this.user.coduri_fiscale : [];
  }

  isSpvAdmin(): boolean {
    if (this.user.roles.find(item => item === 'admin')) {
      return true;
    }
    return false;
  }

  // Metodă pentru a obține profilul utilizatorului
  async getProfile(): Promise<User | undefined> {
    try {
      const user: User | null = await getAuthStatus(); // Obține utilizatorul din local storage
      if (user) {
        this.user = user;
        return user; // Returnează utilizatorul
      } else {
        this.logout();
        return undefined; // Returnează undefined dacă nu există utilizator
      }
    } catch (error) {
      this.logout();
      throw error; // Propagă eroarea
    }
  }

  logout(): void {
    localStorage.removeItem('authentication');
    this.user = null; // Resetare utilizator
    this.router.navigate(['/login']); // Navigare către pagina de login
  }

  isConnected(): boolean {
    return this.user !== null; // Verifică dacă utilizatorul este conectat
  }

  hasAccesss(tag: String): boolean {
    if (this.user.tags.find(item => item === tag)) {
      return true;
    }
    return false;
  }

  hasRole(role: String): boolean {
    if (this.user.roles.find(item => item === role)) {
      return true;
    }
    return false;
  }
}

// Funcția pentru a obține statusul autentificării
export const getAuthStatus = async (): Promise<User | undefined> => {
  await sleep(500); // Simulează întârzierea, pot fi necesare cereri HTTP
  try {
    const authentication: User = JSON.parse(localStorage.getItem('authentication'));
    if (authentication) {
      return {
        nume: authentication.nume,
        prenume: authentication.prenume,
        email: authentication.email,
        password: authentication.password,  // Atenție! Este recomandat să nu expui parola în aplicații reale
        roles: authentication.roles,
        image: authentication.image,
        tags: authentication.tags,
        coduri_fiscale: authentication.coduri_fiscale // Verifică denumirea cheii
      } as User; // Conversie către tipul User
    }
    return undefined; // În cazul în care nu există autentificare
  } catch (error) {
    console.error("Eroare la obținerea statusului de autentificare:", error);
    return undefined; // Returnează undefined în caz de eroare
  }
};

// Funcția sleep, pentru a simula o întârziere. Poate fi definită astfel:
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
