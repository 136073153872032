import { User } from '@/models/user';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '@services/app.service';
import { UserService } from '@services/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tab-users',
  templateUrl: './tab-users.component.html',
  styleUrl: './tab-users.component.scss'
})
export class TabUsersComponent implements OnInit {
  displayedColumns: string[] = ['nume', 'email', 'roles', 'tags', 'actions']
  users: MatTableDataSource<User> = new MatTableDataSource();
  isEditing: boolean = false;

  newUser: User = {
    email: '',
    password: '1234',
    roles: [],
    tags: [],
    coduri_fiscale: [],
    nume: '',
    prenume: ''
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService, 
    private modalService: NgbModal, 
    private toastr: ToastrService,
    private appService: AppService) { }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(userToAdd?: User): void {
    this.userService.getUsers().subscribe(
      (data: User[]) => {
        this.users.data = data; // Salvează utilizatorii în variabila locală
        this.users.paginator = this.paginator;
        this.users.sort = this.sort;
        if (userToAdd) {
          this.users.data.push(userToAdd);
        }
      },
      (error) => {
        console.error('Eroare la obținerea utilizatorilor:', error); // Gestionează erorile
      }
    );
  }

  editUser(user: User) {
    // Logica pentru editarea utilizatorului
    // De exemplu, poți deschide modalul cu informațiile populare
    console.log("Editează utilizatorul:", user);

    // Poți popula newUser cu informațiile utilizatorului selectat pentru editare, dacă dorești
    this.newUser = { ...user }; // Aici poți seta utilizatorul selectat pentru editare
    this.isEditing = true;
  }

  deleteUser(user: User) {
    if(this.appService.getAuthenthificatedUser().email === user.email){
      this.toastr.error(`Utilizatorul ${user.email} nu poate fi șters!`);
    } else {
      const confirmDelete = confirm(`Sigur vrei să ștergi utilizatorul ${user.email}?`);
      if (confirmDelete) {
        this.users.data = this.users.data.filter(u => u !== user);
        // Actualizarea localStorage sau a unui backend poate fi adăugată aici
        this.toastr.success(`Utilizatorul ${user.email} a fost șters!`);
      }
    }

  }

  addUser() {
    if (this.newUser.nume && this.newUser.prenume && this.newUser.email) {
      if (!this.isEditing) {
        this.userService.createUser(this.newUser).subscribe(() => {
          this.users.data.push({ ...this.newUser, roles: ['user'] });
          this.loadUsers({ ...this.newUser, roles: ['user'] });
          this.toastr.success(`Utilizatorul ${this.newUser.nume} ${this.newUser.prenume} a fost adăugat!`);


          this.newUser = {
            email: '',
            password: 'defaultPassword',
            roles: [],
            tags: [],
            coduri_fiscale: [],
            nume: '',
            prenume: ''
          };

          // Închidere modal
          this.modalService.dismissAll();
        });
      } else {
        this.newUser = {
          email: '',
          password: 'defaultPassword',
          roles: [],
          tags: [],
          coduri_fiscale: [],
          nume: '',
          prenume: ''
        };
        this.isEditing = false;
        this.modalService.dismissAll();
      }

    } else {
      this.toastr.error('Te rog completează toate câmpurile necesare!')
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.users.filter = filterValue.trim().toLowerCase(); // Filtrare
  }


  openModal(content: any) {

    this.modalService.open(content);
  }
}
