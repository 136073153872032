import { Component } from '@angular/core';
import { TranslationService } from '@services/translation.service';
import { ActivatedRoute } from '@angular/router';
import menuData from '../../../../../assets/data/spv-menu.json';
import { MenuItem, MenuService } from '@services/menu.service';
import { AppService } from '@services/app.service';
import { User } from '@/models/user';
import { UserService } from '@services/users.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  users: User[] = [];
  userId: number;
  currentUser: User = null;
  currentRoles: String[] = null;
  currentTags: String[] = null;
  codFiscal: string;
  userName: string;
  menu: MenuItem[] = [];


  constructor(
    public translationService: TranslationService,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private appService: AppService,
    private userService: UserService) { }

  ngOnInit(): void {

    this.currentUser = this.appService.user;
    this.currentRoles = this.currentUser.roles;
    this.currentTags = this.currentUser.tags;
    console.log(this.appService.hasAccesss('solicitari'));
    this.route.params.subscribe(params => {
      this.codFiscal = params['id'];
      if (this.codFiscal == undefined) {
        this.codFiscal = 'toate';
      }
      // if (this.userId == 1) {
      //   this.userName = 'ABC SRL';
      // }
      // if (this.userId == 2) {
      //   this.userName = 'XYZ SA';
      // }
      // if (this.userId == undefined || (this.userId != 1 && this.userId != 2)) {
      //   this.userName = 'TOATE';
      // }
    });
    this.loadUsers();
    this.loadMenu();

  }

  loadUsers(): void {
    this.userService.getUsers().subscribe(
      (data: User[]) => {
        this.users = data; // Salvează utilizatorii în variabila locală
      },
      (error) => {
        console.error('Eroare la obținerea utilizatorilor:', error); // Gestionează erorile
      }
    );
  }

  loadMenu() {
    this.menuService.getSPVMenu().subscribe(data => {
      this.menu = data.MENU;
    });
  }

  hasAccess(tag: String): boolean {
    return this.appService.hasAccesss(tag)
  }

}

//https://via.placeholder.com/100
