<table class="table">
    <thead>
      <tr>
        <th>#</th>
        <th>Tip Declarație</th>
        <th>Data Limită</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>Declarație 1</td>
        <td>2024-03-31</td>
        <td>Completară</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Declarație 2</td>
        <td>2024-04-15</td>
        <td>În așteptare</td>
      </tr>
    </tbody>
  </table>