<table class="table">
    <thead>
      <tr>
        <th>#</th>
        <th>Subiect</th>
        <th>Descriere</th>
        <th>Data Publicării</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>FAQ 1</td>
        <td>Întrebare frecventă 1</td>
        <td>2024-03-01</td>
      </tr>
      <tr>
        <td>2</td>
        <td>FAQ 2</td>
        <td>Întrebare frecventă 2</td>
        <td>2024-03-02</td>
      </tr>
    </tbody>
  </table>