import { Component } from '@angular/core';

@Component({
  selector: 'app-tab-solicitari',
  templateUrl: './tab-solicitari.component.html',
  styleUrl: './tab-solicitari.component.scss'
})
export class TabSolicitariComponent {

}
