import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@services/users.service';
import { User } from '@/models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tab-assign-roles',
  templateUrl: './tab-assign-roles.component.html',
  styleUrl: './tab-assign-roles.component.scss'
})

export class TabAssignRolesComponent implements OnInit {
  users: MatTableDataSource<User> = new MatTableDataSource(); // sursa de date pentru tabel
  displayedColumns: string[] = ['nume', 'email', 'roles', 'actions'];

  availableRoles: string[] = ['user', 'admin', 'editor', 'viewer'];
  newRole: string = '';
  user: User = null;

  constructor(private userService: UserService, private dialog: MatDialog, private modalService: NgbModal, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers() {
    this.userService.getUsers().subscribe(
      (data: User[]) => {
        this.users.data = data; // Salvează utilizatorii în MatTableDataSource
      },
      (error) => {
        console.error('Eroare la obținerea utilizatorilor:', error);
      }
    );
  }

  addRole() {
    if (this.newRole) {
      if (!this.user.roles.includes(this.newRole)) {
        this.user.roles.push(this.newRole); // Adaugă rolul
        this.toastr.success(`Rolul "${this.newRole}" a fost adăugat utilizatorului ${this.user.nume} ${this.user.prenume}.`);
        this.newRole = ''; // Resetează câmpul
      } else {
        this.toastr.warning('Rolul este deja atribuit utilizatorului!');
      }
    } else {
      this.toastr.error('Te rog introdu un rol valid!');
     
    }
  }

  removeRole(role: string, user: User) {
   
    this.user = user;
    const index = this.user.roles.indexOf(role);
    console.log(index);
    if (index >= 0) {
      this.user.roles.splice(index, 1); // Elimină rolul
      this.toastr.success(`Rolul "${role}" a fost eliminat utilizatorului ${this.user.nume} ${this.user.prenume}.`);
    }
  }

  openModal(content: any, user: User) {
    this.user = user;
    console.log(user);
    this.modalService.open(content);
  }
}
