import { Component } from '@angular/core';
import { TranslationService } from '@services/translation.service';

@Component({
  selector: 'app-solicitari',
  templateUrl: './solicitari.component.html',
  styleUrl: './solicitari.component.scss'
})
export class SolicitariComponent {
  
  public tabContent = {
    eliberari: 'Conținut pentru Eliberări documente...',
    informatii: 'Informații despre documente...',
    obligatii: 'Obligații de plată...',
    raspunsuri: 'Răspunsuri la solicitări...',
    statusSolicitare: 'Statusul solicitărilor...',
    solicitari: 'Solicitări către alte instituții...'
  };

  constructor(public translationService: TranslationService) { }

}
