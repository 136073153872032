import { Component } from '@angular/core';

@Component({
  selector: 'app-servicii',
  templateUrl: './servicii.component.html',
  styleUrl: './servicii.component.scss'
})
export class ServiciiComponent {

  searchText: string = ''; // Câmp pentru căutare
  
}
