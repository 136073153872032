<table class="table">
    <thead>
      <tr>
        <th>#</th>
        <th>Tip Solicitare</th>
        <th>Data Solicitară</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>Solicitare 1</td>
        <td>2024-03-20</td>
        <td>Procesare</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Solicitare 2</td>
        <td>2024-03-22</td>
        <td>Finalizată</td>
      </tr>
    </tbody>
  </table>