<table class="table">
    <thead>
      <tr>
        <th>#</th>
        <th>Solicitare</th>
        <th>Data</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of solicitariTrimise">
        <td>{{ item.id }}</td>
        <td>{{ item.solicitare }}</td>
        <td>{{ item.data }}</td>
        <td>{{ item.status }}</td>
      </tr>
    </tbody>
  </table>