import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-plati-creante',
  templateUrl: './plati-creante.component.html',
  styleUrls: ['./plati-creante.component.scss']
})
export class PlatiCreanteComponent {



}
