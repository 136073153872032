import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '@services/users.service';
import { User } from '@/models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tab-assign-tags',
  templateUrl: './tab-assign-tags.component.html',
  styleUrls: ['./tab-assign-tags.component.scss']
})
export class TabAssignTagsComponent implements OnInit {
  
  users: MatTableDataSource<User> = new MatTableDataSource(); // Sursa de date pentru tabel
  displayedColumns: string[] = ['nume', 'email', 'tags', 'actions'];

  availableTags: string[] = ['inbox', 'solicitari', 'plati', 'programari', 'buletin_fiscal', 'declaratii', 'e_factura', 'e_transport']; // Etichete disponibile
  newTag: string = '';
  user: User = null;

  constructor(private userService: UserService, private modalService: NgbModal, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(): void {
    this.userService.getUsers().subscribe(
      (data: User[]) => {
        this.users.data = data; // Salvează utilizatorii în MatTableDataSource
      },
      (error) => {
        console.error('Eroare la obținerea utilizatorilor:', error);
      }
    );
  }

  addTag() {
    if (this.newTag) {
      if (!this.user.tags.includes(this.newTag)) {
        this.user.tags.push(this.newTag); // Adaugă tagul
        this.toastr.success(`Tag-ul "${this.newTag}" a fost adăugat utilizatorului ${this.user.nume} ${this.user.prenume}.`);
        this.newTag = ''; // Resetează câmpul
      } else {
        this.toastr.warning('Tag-ul este deja atribuit utilizatorului!');
      }
    } else {
      this.toastr.error('Te rog introdu un tag valid!');
    }
  }

  removeTag(tag: string, user: User) {
    this.user = user;
    const index = this.user.tags.indexOf(tag);
    console.log(index);
    if (index >= 0) {
      this.user.tags.splice(index, 1); // Elimină tagul
      this.toastr.success(`Tag-ul "${tag}" a fost eliminat utilizatorului ${this.user.nume} ${this.user.prenume}.`);
    }
  }

  openModal(content: any, user: User) {
    this.user = user; // Setează utilizatorul curent
    this.modalService.open(content); // Deschide modalul
  }
}
