<!-- src/app/admin-users/admin-users.component.html -->
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-12">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a
                            [routerLink]="['/spv']">SPV</a></li>
                    <li class="breadcrumb-item active">Declaratii</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="content">
    <div class="container-fluid">
        <div class="container">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Utilizatori</h3>
                </div>
                <div class="card-body">
                    <mat-tab-group [(selectedIndex)]="selectedTab">
                        <mat-tab label="Vizualizare Utilizatori">
                            <app-tab-users></app-tab-users>
                        </mat-tab>
                        <mat-tab label="Atribuire Roluri">
                            <app-tab-assign-roles></app-tab-assign-roles>
                        </mat-tab>
                        <mat-tab label="Atribuire Etichete">
                            <app-tab-assign-tags></app-tab-assign-tags>
                        </mat-tab>
                        <mat-tab label="Atribuire Societăți">
                            <!-- <app-assign-companies></app-assign-companies> -->
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>
