<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-12">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a
                            [routerLink]="['/spv']">SPV</a></li>
                    <li class="breadcrumb-item active">Declaratii</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="content">
    <div class="container-fluid">
        <div class="container">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Declaratii</h3>
                </div>
                <div class="card-body">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab"
                                href="#depuneri">Depuneri Declarații</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab"
                                href="#recipise">Recipese</a>
                        </li>                       
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab"
                                href="#arhiva">Arhiva Declarații</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="depuneri" class="tab-pane fade show active">
                            <app-declaratie-formular></app-declaratie-formular>
                        </div>
                        <div id="recipise" class="tab-pane fade">
                            <app-tab-recipise-declaratie></app-tab-recipise-declaratie>
                        </div>
                        <div id="arhiva" class="tab-pane fade">
                            <app-tab-arhiva-declaratii></app-tab-arhiva-declaratii>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

