import { Component } from '@angular/core';

@Component({
  selector: 'app-tab-arhiva-plati',
  templateUrl: './tab-arhiva-plati.component.html',
  styleUrl: './tab-arhiva-plati.component.scss'
})
export class TabArhivaPlatiComponent {

  public creante = [
    { id: 1, tip: 'Taxă pe venit', suma: 100, dataPlatii: '2024-04-01' },
    { id: 2, tip: 'Taxă pe proprietate', suma: 250, dataPlatii: '2024-04-10' },
    { id: 3, tip: 'Taxă auto', suma: 150, dataPlatii: '2024-05-01' }
  ];

}
