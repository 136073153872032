import { Component } from '@angular/core';

@Component({
  selector: 'app-tab-declaratii',
  templateUrl: './tab-declaratii.component.html',
  styleUrl: './tab-declaratii.component.scss'
})
export class TabDeclaratiiComponent {

}
